<template>
  <el-form ref="form" :model="form" label-width="80px">
    <el-form-item label="栏目列表" v-if="column.length > 0">
      <el-tag
        v-for="tag in column"
        :key="tag.id"
        closable
        @close="handleClose(tag)"
        class="m-r-10"
      >
        {{ tag.columnName }}
      </el-tag>
    </el-form-item>
    <el-form-item label="栏目名称">
      <el-input v-model="form.name"></el-input>
    </el-form-item>
    <el-form-item label="栏目是否展示">
        <el-switch
  v-model="value"
  active-color="#13ce66"
  inactive-color="#ff4949">
</el-switch>
    </el-form-item>
  
    <el-form-item label="栏目标签">
      <el-checkbox-group v-model="columnType">
        <el-checkbox
          v-for="label in form.type"
          :key="label.id"
          :label="label.name"
          :name="label.name"
        ></el-checkbox>
      </el-checkbox-group>
    </el-form-item>
    <el-form-item label="栏目简介">
      <el-input type="textarea" v-model="form.desc"></el-input>
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="onSubmit">立即创建</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { getAllLable } from "@/api/lable";
import { addColumn, deleteColumn, getAllColumn } from "@/api/column";
export default {
  data() {
    return {
      form: {
        name: "",
        region: "",
        type: [],
        resource: "",
        desc: "",
      },
      value:true,
      column: [],
      columnType:[]
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      getAllLable().then((res) => {
        console.log(res);
        if (res.status === 200) {
          this.form.type = res.data;
        }
      });
        getAllColumn().then((res) => {
        console.log(res);
        if (res.status === 200) {
          this.column = res.data;
        }
      });
    },
  
    handleClose(data) {
      deleteColumn({ id: data.id }).then((res) => {
        console.log(res);
        if (res.status === 200) {
          this.column.map((item,index)=>{
            if (item.id === data.id) {
              this.column.splice(index,1)
            }
          })
        }
      });
    },
   
    onSubmit() {
      let lable = ''
      if (this.columnType.length > 0) {
        this.columnType.map((item,index)=>{
          if (index > 0) {
            lable = lable+','+item; 
          }else{
            lable = item
          }
        })
      }
      console.log(this.form.value ? 1:0)
      addColumn({ columnName: this.form.name,introduce:this.form.desc,lable:lable,columnId:this.form.region,type:this.form.value ? 1:0}).then((res) => {
        console.log(res);
       // this.getlable();
       if (res.status === 200) { 
         this.column.push(res.data)
       }
      });
      console.log("submit!");
    },
  },
};
</script>