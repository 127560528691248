import request from '@/axios/request';
import qs from "qs";

export function lableAdd(data) {
    return request({
      url: '/api/lable/addLable',
      method: 'post',
      data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
    });
}
export function deleteLable(data) {
  return request({
    url: '/api/lable/deleteLable',
    method: 'post',
    data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
  });
}
export function getAllLable() {
  return request({
    url: '/api/lable/getAllLable',
    method: 'get'
  });
}